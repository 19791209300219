<template>
  <div class="main-content-box">
    <div class="search-box">
      <div class="search">
        <img class="icon" src="@/assets/works_search_icon.png">
        <input type="text" placeholder="请输入搜索内容" v-model.trim="searchObj.keywords">
      </div>
    </div>
    <div class="main-content mb35">
      <div class="main-title">筛选</div>
      <div class="main-btn" @click="startALLBak">
        <span class="btn-add">打包下载全部资料</span>
        <img class="icon" src="@/assets/works_downloads_icon.png">
      </div>
      <div class="from">
        <div class="row">
          <div class="row-box">
            <div class="block-box w40">
              <div class="title">赛道</div>
              <div class="value">
                <el-select v-model="searchObj.race" placeholder="请选择赛道" @change="changeRace()">
                  <el-option
                      class="sel"
                      v-for="item in raceArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="block-box w60 pl15">
              <div class="title">作品编号</div>
              <input type="text" placeholder="请输入作品编号" v-model.trim="searchObj.works_no">
            </div>
            <div class="block-box">
              <div class="title">公司/单位</div>
              <div class="value">
                <el-select v-model="searchObj.company" filterable remote placeholder="请选择公司/单位" :remote-method="searchCompany" @change="changeCompany()">
                  <el-option
                      v-for="item in arrCompany"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row-box">
            <div class="block-box pl15">
              <div class="title">主题</div>
              <div class="value">
                <el-select v-model="searchObj.topic" placeholder="请选择主题" @change="changeTopic($event)">
                  <el-option
                      class="sel"
                      v-for="item in topicArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="block-box pl15">
              <div class="title">作者</div>
              <div class="value">
                <el-select v-model="searchObj.author" filterable remote placeholder="请选择作者" :remote-method="searchAuthor" >
                  <el-option
                      v-for="item in arrAuthor"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="row-box">
            <div class="block-box pl15">
              <div class="title">院校</div>
              <div class="value">
                <el-select v-model="searchObj.school" filterable remote placeholder="请选择院校" :remote-method="searchSchool" @change="changeSchool()">
                  <el-option
                      v-for="item in arrSchool"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="block-box pl15">
              <div class="title">指导老师</div>
              <div class="value">
                <el-select v-model="searchObj.teacher" filterable remote placeholder="请选择指导老师" :remote-method="searchTeacher" >
                  <el-option
                      v-for="item in arrTeacher"
                      :key="item"
                      :label="item"
                      :value="item">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-box">
          <span class="btn-confirm" @click="getList(0)">搜索</span>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="main-title">作品列表</div>
      <div class="main-btn" @click="startSelBak">
        <span class="btn-add">打包下载当前信息</span>
        <img class="icon" src="@/assets/works_downloads_icon.png">
      </div>
      <div class="main-btn2" @click="startSelBak">
        <span class="btn-add">导出EXCEL</span>
        <img class="icon" src="@/assets/works_downloads_icon.png">
      </div>
      <table>
        <tr>
          <th class="td-left" width="100">组别</th>
          <th class="td-left" width="150">作者姓名</th>
          <!--<th class="td-left" width="150">获奖露出名称</th>-->
          <th class="td-left" >作品名称</th>
          <th class="td-left" width="150">作品编号</th>
          <th class="td-left" width="150">主题</th>
          <th class="td-left" width="200">院校/公司</th>
          <th width="50"></th>
        </tr>
        <template v-for="(item) in list">
          <tr class="row" :key="item.id" @click="rowSelect(item.id)">
            <td class="td-left">{{item.race}}</td>
            <td>{{item.realname}}</td>
            <!--<td>{{item.school}}</td>-->
            <td>{{item.title}}</td>
            <td>{{item.work_no}}</td>
            <td>{{item.topic}}</td>
            <td>{{item.company}}</td>
            <td class="td-center">
              <img class="more" src="@/assets/list_icon_more.png">
              <div class="more-box" v-if="selectRow == item.id">
                <div class="op-row" @click="goInfo(item.id)">查看</div>
                <div class="op-row" @click="goDownload(item.id)">下载</div>
                <div class="op-row" @click="goCert(item.id)">证书</div>
              </div>
            </td>
          </tr>
        </template>
      </table>
      <div class="paging-box">
        <paging :current-page="page" :total="total" :page-size="9" @currentChange="pageChange"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getWorksList,startBakWorks,startBakWorksBySearch,workListExcel,getWorkRaceList,getWorkRaceTopicList,getSearchFilter} from "@/request/api";
import paging from "@/components/paging";
import {Warning} from "@/plugins/warning";

export default {
  name: "works_list",
  components:{
    paging
  },
  data() {
    return {
      list:[],
      page: 0,
      total: 0,
      totalPage: 0,
      selectRow: 0,
      searchObj:{"race":0,"topic":0,"keywords":"","school":"","company":"","author":"","teacher":""},
      raceArr:[],
      topicArr:[{"id":0,"name":"请选择"}],
      arrCompany:[],
      arrSchool:[],
      arrAuthor:[],
      arrTeacher:[],
    }
  },
  computed: {

  },
  created() {
    this.searchObj.keywords = this.$route.query.keywords;
    this.getRaceList();
    this.getList();
  },
  mounted() {

  },
  methods: {
    getList(e){
      const curPage = e || this.page
      if(this.searchObj.keywords == undefined){
        this.searchObj.keywords = '';
      }
      if(this.searchObj.works_no == undefined){
        this.searchObj.works_no = '';
      }
      const requestData = {
        page: curPage,
        race: this.searchObj.race,
        topic: this.searchObj.topic,
        title: this.searchObj.keywords,
        school: this.searchObj.school,
        company: this.searchObj.company,
        author: this.searchObj.author,
        teacher: this.searchObj.teacher,
        works_no: this.searchObj.works_no,
      };
      getWorksList(requestData).then((res)=>{
        this.list = res.data.list;
        this.total = res.data.paging.recordCount
        this.totalPage = res.data.paging.pageCount
        this.page = curPage
      })
    },
    pageChange(e){
      this.getList(e)
    },
    rowSelect(sel){
      if(sel == this.selectRow){
        this.selectRow = 0;
      }else{
        this.selectRow = sel;
      }
    },
    goInfo(sel){
      this.selectRow = 0;
      //this.$router.push({name:"work_info",query:{id:sel}}, ()=>{}, ()=>{})

      let routeUrl = this.$router.resolve({name:"work_info",query:{id:sel}}, ()=>{}, ()=>{})
      window.open(routeUrl.href, '_blank')
    },
    goDownload(sel){
      this.selectRow = 0;
      const requestData = {
        works_ids: sel,
      };
      /*
      Warning.success("正在准备打包下载，请稍后");
      getWorksDownload(requestData).then((res)=>{
        const a = document.createElement('a');
        a.style.display = 'none';
        a.setAttribute('target', '_blank');
        a.href = res.data.url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        Warning.success("打包完毕");
        this.selectRow = 0;
      })*/

      this.$confirm("确定是否开始打包?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        startBakWorks(requestData).then(()=>{
          Warning.success('打包已经开始，请等待打包完成后前往作品打包列表下载打包文件');
        })
      })
    },
    goCert(sel){
      this.selectRow = 0;
      //this.$router.push({name:"work_award_edit",query:{id:sel}}, ()=>{}, ()=>{})

      let routeUrl = this.$router.resolve({name:"work_award_edit",query:{id:sel}}, ()=>{}, ()=>{})
      window.open(routeUrl.href, '_blank')

    },
    startALLBak(){
      this.$confirm("确定是否开始打包?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const requestData = {};
        startBakWorks(requestData).then(()=>{
          Warning.success('打包已经开始，请等待打包完成后前往作品打包列表下载打包文件');
          //this.getList(0);
          /*
          setTimeout(()=>{
            this.$router.push({name:'work_package'})
          },2500)*/
        })
      })
    },
    startSelBak(){
      this.$confirm("确定是否开始打包?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const requestData = {
          race: this.searchObj.race,
          topic: this.searchObj.topic,
          title: this.searchObj.keywords,
          school: this.searchObj.school,
          company: this.searchObj.company,
          author: this.searchObj.author,
          teacher: this.searchObj.teacher,
          works_no: this.searchObj.works_no,
        };
        workListExcel(requestData).then((res)=>{
          window.location.href = res.data.url;
        })
      })
    },
    startSelEXCEL(){
      this.$confirm("确定是否下载EXCEL?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const requestData = {
          race: this.searchObj.race,
          topic: this.searchObj.topic,
          title: this.searchObj.keywords,
          school: this.searchObj.school,
          company: this.searchObj.company,
          author: this.searchObj.author,
          teacher: this.searchObj.teacher,
          works_no: this.searchObj.works_no,
        };
        startBakWorksBySearch(requestData).then(()=>{
          Warning.success('打包已经开始，请等待打包完成后下载打包文件');
          //this.getList(0);
          setTimeout(()=>{
            this.$router.push({name:'work_package'})
          },2500)
        })
      })
    },
    changeRace(){
      console.log("aaa")
      this.getRaceTopicList();
      this.$set(this.searchObj, 'topic', null)
    },
    changeTopic(e){
      console.log(e)
      //this.searchObj.topic = e;
    },
    changeCompany(){
      this.searchObj.school = "";
    },
    changeSchool(){
      this.searchObj.company = "";
    },
    getRaceList(){
      const requestData = {
        page: 0,
        size: 999
      };
      getWorkRaceList(requestData).then((res)=>{
        this.raceArr = res.data.list;
        this.raceArr.push({"id":0,"name":"请选择"})
      })
    },
    getRaceTopicList(){
      const requestData = {
        race_id: this.searchObj.race,
        page: 0,
        size: 999
      };
      getWorkRaceTopicList(requestData).then((res)=>{
        this.topicArr = res.data.list;
        this.topicArr.push({"id":0,"name":"请选择"})
      })
    },
    searchCompany(key){
      if(key == null || key == undefined || key == ''){
        this.arrCompany = [];
        return;
      }
      const requestData = {
        company: key,
      };
      getSearchFilter(requestData).then((res)=>{
        this.arrCompany = res.data;
      })
    },
    searchSchool(key){
      if(key == null || key == undefined || key == ''){
        this.arrSchool = [];
        return;
      }
      const requestData = {
        school: key,
      };
      getSearchFilter(requestData).then((res)=>{
        this.arrSchool = res.data;
      })
    },
    searchTeacher(key){
      if(key == null || key == undefined || key == ''){
        this.arrTeacher = [];
        return;
      }
      const requestData = {
        teacher: key,
      };
      getSearchFilter(requestData).then((res)=>{
        this.arrTeacher = res.data;
      })
    },
    searchAuthor(key){
      if(key == null || key == undefined || key == ''){
        this.arrAuthor = [];
        return;
      }
      const requestData = {
        author: key,
      };
      getSearchFilter(requestData).then((res)=>{
        this.arrAuthor = res.data;
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@/css/_var.scss";
.search-box{
  width: 100%;
  height: 50px;
  margin-bottom: 35px;
  position: relative;
  .search{
    width: 350px;
    height: 50px;
    border-radius: 30px;
    border: 2px solid #DDD1B4;
    background: #f9f9f9;
    position: absolute;
    top: 0;
    right: 0;
    .icon{
      width: 30px;
      height: 30px;
      margin-left: 15px;
      vertical-align:middle;
    }
    & > input{
      height: 50px;
      width: 300px;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align:middle;
    }
  }
}
.main-content{
  width: 100%;
  border-radius: 30px;
  background: #fff;
  box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
  display: flex;
  //justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-bottom: 45px;
  .main-title{
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-left: 30px;
    margin-top: 30px;
  }
  .main-btn{
    width: 200px;
    height: 50px;
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-top: 30px;
    position: absolute;
    cursor: pointer;
    right: 90px;
    .btn-add{
      display: block;
      line-height: 50px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #DC2525;
      vertical-align:middle;
      float: left;
    }
    .icon{
      width: 30px;
      height: 30px;
      vertical-align:middle;
      float: left;
      position:absolute;
      top:10px
    }
  }
  .main-btn2{
    width: 200px;
    height: 50px;
    font-size: 24px;
    font-weight: 600;
    color: #141414;
    margin-top: 30px;
    position: absolute;
    cursor: pointer;
    right: 250px;
    .btn-add{
      display: block;
      line-height: 50px;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #DC2525;
      vertical-align:middle;
      float: left;
    }
    .icon{
      width: 30px;
      height: 30px;
      vertical-align:middle;
      float: left;
      position:absolute;
      top:10px
    }
  }
  & > table{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    & > tr{
      & > th{
        font-size: 18px;
        font-family: Jost-Medium, Jost;
        font-weight: 500;
        color: #000;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    .td-left{
      text-align: left;
    }
    .td-center{
      text-align: center;
    }
    .row{
      background: #f8f6f0;
      border-radius: 15px;
      box-shadow: 0 0 0 5px #f8f6f0;
      height: 50px;
      font-size: 17px;
      font-family: PingFang HK-Regular, PingFang HK;
      font-weight: 400;
      color: #2E2E2E;
      position: relative;
      cursor: pointer;
      td{
        vertical-align:middle;
        padding-left: 15px;
        padding-right: 15px;
      }
      .thumb{
        width: 150px;
        height: 100px;
        object-fit: cover;
        display:table-cell;
        vertical-align:middle;
      }
      .more{
        width: 25px;
        height: 25px;
        margin-top: 15px;
      }
      .more-box{
        width: 45px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 15px 30px  rgba(0, 0, 0, .1);
        position: absolute;
        top: 20px;
        right: 35px;
        z-index: 999;
        padding-top: 5px;
        padding-bottom: 5px;
        .op-row{
          height: 25px;
          line-height: 25px;
          width: 45px;
          font-size: 12px;
          font-weight: 300;
          color: #2E2E2E;
          cursor: pointer;
        }
      }
      .red{
        color: red;
      }
      .green{
        color: green;
      }
    }
  }
  .from{
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;
    border-collapse:separate;
    border-spacing:0px 30px;
    position: relative;
    .row{
      width: 100%;
      padding-top: 15px;
      padding-left: 15px;
      padding-right: 15px;
      .row-box{
        width: 33%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        float: left;
        position: relative;
        .block-box{
          width: 100%;
          margin-top: 15px;
        }
        .w30{
          width: 30%;
        }
        .w70{
          width: 70%;
        }
        .w40{
          width: 40%;
        }
        .w60{
          width: 60%;
        }
        .pl15{
          padding-left: 15px;
        }
        .pr15{
          padding-right: 15px;
        }
        .title{
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #000000;
          margin-bottom: 15px;
        }
        .value{
          height: 50px;
          width: 100%;
          display:flex;/*实现垂直居中*/
          align-items:center;/*实现水平居中*/
          border-radius: 25px 25px 25px 25px;
          opacity: 1;
          border: 2px solid #DDD1B4;
          padding-left: 15px;
          padding-right: 15px;
          margin-right: 30px;
          .el-select{
            width: 100%;
            height: 40px;
            border: 0px;
            .el-select__tags{
              max-height: 50px;
              border: 0;
            }
          }
        }
        .block-box{
          & > input{
            height: 50px;
            width: 100%;
            display:flex;/*实现垂直居中*/
            align-items:center;/*实现水平居中*/
            border-radius: 25px 25px 25px 25px;
            opacity: 1;
            border: 2px solid #DDD1B4;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 30px;
          }
        }
      }
    }
    .btn-box{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 40px;
      padding-left: 15px;
      padding-right: 15px;
      .btn-confirm{
        display: block;
        width: 100%;
        line-height: 48px;
        font-size: 21px;
        font-weight: 300;
        text-align: center;
        cursor: pointer;
        color: #fff;
        border-radius: 25px;
        background-color: #ba0704;
      }
    }
  }

  .paging-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //padding: 0 130px;
  }
}
.mb35{
  margin-bottom: 35px;
}
</style>
<style>
.el-input__inner{
  border: 0;
}
</style>
